import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Pre Open WOD 5`}</strong></p>
    <p>{`18:00 AMRAP of:`}</p>
    <p>{`60 Calorie Row`}</p>
    <p>{`50 Walking Lunge Steps `}<em parentName="p">{`(Must reach full hip extension on each lunge)`}</em></p>
    <p>{`40 KB SDHP’s (53/35) `}<em parentName="p">{`(KB handle to clavicle height)`}</em></p>
    <p>{`30 S2OH (135/95) `}<em parentName="p">{`(Masters & Scaled Division 95/65)`}</em></p>
    <p>{`20 50ft Sprints `}<em parentName="p">{`(Hand must touch the ground at start and end of each
50ft increment)`}</em></p>
    <p>{`10 Rope Climbs to 15′ `}<em parentName="p">{`(Masters & Scaled Division 10 Rope Pulls from
lying on ground to full standing position, straight legs)`}</em></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar with Dawn from Sports Nutrition 2 Go in Cincinnati. Want to
learn the exact number of calories your body burns each day? Metabolic
Testing will be offered before and after the seminar as well! We will
need 20 people in order to have this seminar so please email
Daniel\\@crossfitlouisvilleeast.com if you’d like to attend. \\$35/person
for the seminar.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      